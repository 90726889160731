function moveElement(detachingEle, attachingEle) {
  let img = $('.' + detachingEle).detach();
  $('.' + attachingEle).append(img);
}

let elementToMove = 'js-move-finishes';
let desktopAnchor = 'wheel-detail-stage';
let mobileAnchor = 'wheel-detail-info';

if ($('.' + elementToMove).length) {
  if (Foundation.MediaQuery.atLeast('medium')) {
    moveElement(elementToMove, desktopAnchor);
  }
  $('.' + elementToMove).removeClass('hide');

  $(window).on('changed.zf.mediaquery', function () {
    if (Foundation.MediaQuery.atLeast('medium')) {
      moveElement(elementToMove, desktopAnchor);
    } else {
      moveElement(elementToMove, mobileAnchor);
    }
  });
}

///// Cart Functionality /////
if($('#wheel').length) {
  /*
  // finish display
  $('input[name="finish"]').change(function(){
    let $finish = $(this).data('finishitem');
    let $totalItems = $('.finish-item-options').data('items');
    let $category = $('.cart-options').data('couponevent');

    if($finish == 'custom') {
      $('.finish-item-options.custom').fadeIn();
      $('.finish-item-options.standard').fadeOut();
      updateCategory($totalItems,$category);
    } else {
      $('.finish-item-options.custom').fadeOut();
      $('.finish-item-options.standard').fadeIn();
  
      // reset pricing to original if set to standard;
      
      let $finish = $(`input[name="1:Finish"]`).data('original');
      let $finishValue = 0;
      let $finishKey = $(`input[name="1:Finish"]`).data('finishkey');
      let $color = '';
      let $colorValue = 0;
      let standardFinishCount = $('.standard input[name="finish-finish"').length;
      $category = '';

      updatePrice($totalItems,$color,$colorValue,$finish,$finishValue,'');
      updateCategory($totalItems,$category);

      $('.custom input[name="finish-finish"').each(function(){
        $(this).prop('checked', false);
      });
      $('input[name="finish-color"').each(function(){
        $(this).prop('checked', false);
      });
      
      if( standardFinishCount < 2 ) {
        $('.standard input[name="finish-finish"').prop('checked', true);
      }
      $('input[name="finish-color-other"]').val('');
    }
    isReadyToSubmit();
  });

  // add to hidden - finish
  $('.finish-item-options input[name="finish-finish"]').change(function(){
    let $totalItems = $('.finish-item-options').data('items');
    let $finish = $(this).data('finish');
    let $finishValue = $(this).data('price');
    let $finishKey = $(this).data('finishkey');
    let $colorOption = $('input[name="finish-color"]:checked').data('coloritem');
    let $color = $('input[name="finish-color-other"]').val();
    let $colorValue = $('input[name="finish-color"]:checked').data('price');

    // defined the undefined color
    if (typeof $colorOption == 'undefined') {
      $colorOption = $('input[name="finish-color"]:checked').data('coloritem');
      if($colorOption == 'other' ){
        $color = $('input[name="finish-color-other"]').val();
      } else {
        $color = '';
      }
    } else if ($colorOption == 'other' ){
      $color = $('input[name="finish-color-other"]').val();
    }

    if (typeof $colorValue == 'undefined') {
      $colorValue = $('input[name="finish-color"]:checked').data('price');
      if (typeof $colorValue == 'undefined') {
        $colorValue = 0;
      }
    }

    isReadyToSubmit();
    updatePrice($totalItems,$color,$colorValue,$finish,$finishValue,$finishKey);
  });

  // add to hidden - finish and color
  $('.finish-item-options input[name="finish-color"]').change(function(){
    let $totalItems = $('.finish-item-options').data('items');
    let $finish = $('input[name="finish-finish"]:checked').data('finish');
    let $finishValue = $('input[name="finish-finish"]:checked').data('price');
    let $colorOption = $(this).data('coloritem');
    let $color = '';
    let $colorValue = $(this).data('price');

    // defined the undefined finish
    if (typeof $finish == 'undefined') {
      $finish = $('input[name="finish-finish"]:checked').data('finish');
      if (typeof $finish == 'undefined') {
        $finish = 'Brushed Titanium';
      }
    }
    if (typeof $finishValue == 'undefined') {
      $finishValue = $('input[name="finish-finish"]:checked').data('price');
      if (typeof $finishValue == 'undefined') {
        $finishValue = 0;
      }
    }

    // defined the undefined color
    if ($colorOption == 'other' ){
      $color = $('input[name="finish-color-other"]').val();
    } 
    
    isReadyToSubmit();
    updatePrice($totalItems,$color,$colorValue,$finish,$finishValue,'');
  });

  // cart submit - set color
  $('.cart-submit').click(function(){
    let $totalItems = $('.finish-item-options').data('items');
    let $finish = $('input[name="finish-finish"]:checked').data('finish');
    let $finishValue = $('input[name="finish-finish"]:checked').data('price');
    let $finishKey = $('input[name="finish-finish"]:checked').data('finishkey');
    let $color = $('input[name="finish-color-other"]').val();
    let $colorValue = $('input[name="finish-color"]:checked').data('price');
    if (typeof $finish == 'undefined') {
      $finish = 'Brushed Titanium';
    }
    if (typeof $finishValue == 'undefined') {
      $finishValue = 0;
    }
    if (typeof $color == 'undefined') {
      $color = '';
    }
    if (typeof $colorValue == 'undefined') {
      $colorValue = 0;
    }
    updatePrice($totalItems,$color,$colorValue,$finish,$finishValue,$finishKey);

    $('form').submit();
  });

  $('.finish-quantity, input[name="finish-color-other"]').on('change',function(){
    isReadyToSubmit();
  });

  $('.finish-quantity, input[name="finish-color-other"]').on('click',function(){
    isReadyToSubmit();
  });

  $('input[name="finish-color-other"]').on('keyup',function(){
    isReadyToSubmit();
  });

  function isReadyToSubmit() {
    let $totalItems = $('.finish-item-options').data('items');
    let $qtyCheck = false;
    let $finishCheck = false;
    let $finishValue = $(`input[name="finish"]:checked`).val();

    // check finish
    if (typeof $finishValue != 'undefined') {
      let $finish = $(`input[name="finish"]:checked`).data('finishitem');

      if($finish == 'custom') {
        let $finishFinish = $(`.custom input[name="finish-finish"]`);
        let $finishColor = $(`.custom input[name="finish-color"]`);
        let $finishColorOther = $(`.custom input[name="finish-color-other"]`);
        let $customFinishCheck = false;
        let $customColorCheck = false;
        // loop through finish selections
        $finishFinish.each(function(){
          if ($(this).prop('checked')) {
            $customFinishCheck = true;
          } 
        });
        // loop through color selections
        $finishColor.each(function(){
          if ($(this).prop('checked')) {
            if($(this).data('coloritem') == 'other') {
              if($finishColorOther.val() != '' && typeof $finishColorOther.val() != 'undefined') {
                $customColorCheck = true;
              } 
            } else {
              $customColorCheck = true;
            }
          } 
        });
        if($customColorCheck == true && $customFinishCheck == true) {
          $finishCheck = true;  
        }
      } else {
        $finishCheck = true;
      }
    }

    // check quantity
    for (let i=0; i<$totalItems; i++){
      let $itemCount = i+1
      let $qtyValue = $(`input[name="${$itemCount}:quantity"]`).val();
      
      if($qtyValue > 0) {
        $qtyCheck = true;
      }
    }

    if ($qtyCheck == true && $finishCheck == true) {
      $('button.cart-submit').prop('disabled',false);    
    } else {
      $('button.cart-submit').prop('disabled',true);
    }
     
  }

  function updatePrice($totalItems,$color,$colorValue,$finish,$finishValue,$finishKey) {
    for (let i=0; i<$totalItems; i++){
      let $itemCount = i+1
      let $colorHTML = $(`input[name="${$itemCount}:Color"]`);
      let $finishHTML = $(`input[name="${$itemCount}:Finish"]`);
      let $priceHTML = $(`input[name="${$itemCount}:price"]`);
      let $skuHTML = $(`input[name="${$itemCount}:SKU"]`);
      let $currentPrice = $priceHTML.data('original');
      let $price = parseInt($currentPrice) + parseInt($finishValue) + parseInt($colorValue);
      let $sku = $skuHTML.data('original');
      if($finishKey !== '') {
        $sku = $skuHTML.data('short')+$finishKey;
      }
      
      $finishHTML.val($finish);
      $colorHTML.val($color);
      $priceHTML.val($price);
      $skuHTML.val($sku);
    }
  }

  function updateCategory($totalItems,$category) {
    for (let i=0; i<$totalItems; i++){
      let $itemCount = i+1
      let $categoryHTML = $(`input[name="${$itemCount}:category"]`);
      
      $categoryHTML.val($category);
    }
  }
*/


  // snipcart functionality;
  let cartData = $('.json.product-items').html();
  cartData = JSON.parse(cartData);

  let finishData = $('.json.product-finishes').html();
  finishData = JSON.parse(finishData);

  let items = [];
  for(let i = 0; i < cartData.length; i++) {
    let temp = { 'qty' : 0,
      'finishID': cartData[i].intFinishID,
      'finish': cartData[i].finishNameFull,
      'size' : cartData[i].vchrSize,
      'sku' : cartData[i].vchrPartNumber,
      'bolt' : cartData[i].vchrBoltPat1,
      'offset' : cartData[i].vchrOffset,
      'price' : cartData[i].mapPrice,
      'url' : cartData[i].URL,
      'image' : cartData[i].IMAGEURL,
      'name' : cartData[i].vchrName,
      'description' : ''
    }
    items.push(temp);
  
    //items[i].qty = 0;
    //items[i].finishID = cartData[i].intFinishID;
    //items[i].size = cartData[i].vchrSize;
    //items[i].sku = cartData[i].vchrPartNumber;
  }

  // get data via standard finish
  let tempFinishID = 0;
  let tempSize = '';
  let tempQty = 0;
  let tempCustom = '';
  let tempColor = '';

  // finish 
  $('input[name="finish"]').change(function(){    
    // reset finishes 
    $('.snipcart-add-item').each(function(){
      $(this).removeClass('snipcart-add-item');
    });
    $('input[name="finish-finish"]').each(function(){
      $(this).prop('checked', false);
    });
    $('input[name="finish-color"]').each(function(){
      $(this).prop('checked', false);
    });
    // reset add to cart
    $('button.add-to-cart').prop('disabled',true);

    if($(this).prop('checked') && $(this).attr('data-finishitem') == 'custom') {
      //console.log('we have a custom order;')  
      // display list
      $('.finish-item-options.custom').fadeIn();
      $('.finish-item-options.standard').fadeOut();
      // setup custom information
      $('.item-custom-information').addClass('snipcart-add-item');
    } else {
      // dispalu list
      $('.finish-item-options.custom').fadeOut();
      $('.finish-item-options.standard').fadeIn();
      // remove custom
      $('.item-custom-information').removeClass('snipcart-add-item');
    }
  });

  // standard finish
  $('.standard input[name="finish-finish"]').change(function(){
    // reset quantity;
    $('.item-wheel-information').each(function(){
      $(this).attr('data-item-quantity',0);
      $(this).removeClass('snipcart-add-item');
    });
    // set finish
    tempFinishID = $(this).attr('data-finishid');
    //console.log(tempFinishID);
    // update cart
    $('.finish-quantity').each(function(){
      let tempQty = $(this).val();
      let tempSize = $(this).parent().find('.finish-size').val();
      updateCart(tempFinishID,tempSize,tempQty);
    });
  });

  // custom finish
  $('.custom input[name="finish-finish"]').change(function(){
    tempCustom = $(this).attr('data-finish');
    updateCustom(tempCustom,tempColor);
  });
  // custom color
  $('.custom input[name="finish-color"]').change(function(){
    tempColor = $(this).attr('data-coloritem');
    updateCustom(tempCustom,tempColor);
  });
  $('.custom input[name="finish-color-other"]').on('input',function(){
    let tempColor = '';
    $('.custom input[name="finish-color"]').each(function(){
      if($(this).attr('data-coloritem') != '') {
      tempColor = $(this).attr('data-coloritem');
      }
    });
    updateCustom(tempCustom,tempColor);
  });

  // quantity
  $('.finish-quantity').change(function(){
    tempQty = $(this).val();
    tempSize = $(this).parent().find('.finish-size').val();
    let tempTotalQty = 0;
    $('.item-wheel-information').each(function(){
      if($(this).attr('data-size') == tempSize) {
        $(this).attr('data-item-quantity',tempQty);
        tempTotalQty = tempTotalQty + Number(tempQty);
      }
    });
    updateCart(tempFinishID,tempSize,tempQty);
    // update custom finish
    $('.item-custom-information').attr('data-item-quantity',tempTotalQty);
    if($('.item-custom-information').hasClass('snipcart-add-item')){
      //console.log('custom ready to go');
      let readyCustom = $('.custom input[name="finish-finish"]:checked').attr('data-finish');
      let readyColor = $('.custom input[name="finish-color"]:checked').attr('data-coloritem');
      updateCustom(readyCustom,readyColor);
    }
  });

  //functions
  function updateCart(finish,size,qty) {
    let addToCart = '';
    let cartCount = 0;
    let itemInfo = '';
    if(finish > 0 && size != '') {
      // set item info
      for(let i = 0; i < items.length; i++) {
        if(items[i].finishID == finish && items[i].size == size) {
          items[i].qty = qty;
          items[i].description = 'SKU: '+items[i].sku+' | Size: '+items[i].size+' | Finish: '+items[i].finish+' | Offset: '+items[i].offset+' | Bolt: '+items[i].bolt;
          itemInfo = items[i];
        }
      }

      if(itemInfo != '') {
        $('.item-wheel-information').each(function(){
          let wheelSize = $(this).attr('data-size');
          let wheelFinish = $(this).attr('data-finish');
          if (wheelSize == itemInfo.size && wheelFinish == itemInfo.finishID) {
            //console.log('we have a match');
            $(this).addClass('snipcart-add-item');
            $(this).attr('data-item-quantity',itemInfo.qty);
            $(this).attr('data-item-description',itemInfo.description);
            $(this).attr('data-item-image',itemInfo.image);
            // if quantity available 
            if (itemInfo.qty > 0) {
              $(this).addClass('snipcart-add-item');
            } else {
              $(this).removeClass('snipcart-add-item');
            }
          }
        });
      }
      if ($('.snipcart-add-item.item-wheel-information').length) {
        $('button.add-to-cart').prop('disabled',false);    
      } else {
        $('button.add-to-cart').prop('disabled',true);
      }

    } else if (typeof finish == 'string') {
      //console.log('custome time');
      for(let i = 0; i < items.length; i++) {
        if(items[i].size == size) {
          items[i].qty = qty;
          items[i].description = 'SKU: '+items[i].sku+' | Size: '+items[i].size+' | Finish: '+items[i].finish+' | Offset: '+items[i].offset+' | Bolt: '+items[i].bolt;
          itemInfo = items[i];
          break;
        }
      }

      if(itemInfo != '') {
        $('.item-wheel-information').each(function(){
          let wheelSize = $(this).attr('data-size');
          let wheelFinish = $(this).attr('data-finish');
          if (wheelSize == itemInfo.size && wheelFinish == itemInfo.finishID) {
            //console.log('we have a match');
            $(this).addClass('snipcart-add-item');
            $(this).attr('data-item-quantity',itemInfo.qty);
            $(this).attr('data-item-description',itemInfo.description);
            $(this).attr('data-item-image',itemInfo.image);
            // if quantity available 
            if (itemInfo.qty > 0) {
              $(this).addClass('snipcart-add-item');
            } else {
              $(this).removeClass('snipcart-add-item');
            }
          }
        });
      }
      if ($('.snipcart-add-item.item-wheel-information').length) {
        $('button.add-to-cart').prop('disabled',false);    
      } else {
        $('button.add-to-cart').prop('disabled',true);
      }
    } else {
      //console.log('you are is a failures');
      return false;
    }
  }

  function updateCustom(finish,color) {
    console.log(finish,color);
    let itemInfo = '';
    let description = '';
    let finishReady = false;
    let colorReady = false;
    let totalQty = 0;
    let colorOption = 'Bare Metal'
    if(finish != '' && color != '') {
      // set item info
      for(let i = 0; i < finishData.length; i++) {
        if(finishData[i].FINISH == finish) {
          itemInfo = finishData[i];
        }
      }

      let skuUppercase = itemInfo.SKU.toUpperCase();
      
      // set color
      if(color == 'other') {
        colorOption = $('input[name="finish-color-other"]').val();
      }
      description = 'SKU: '+skuUppercase+' | Finish: '+itemInfo.FINISH+' | Color: '+colorOption;

      // set quantity
      $('.finish-quantity').each(function(){
        totalQty = totalQty + Number($(this).val());
      });

      // set for cart
      // - set up custom
      let customInfo = $('.item-custom-information');
      customInfo.attr('data-item-id',skuUppercase);
      customInfo.attr('data-item-price',itemInfo.PRICE);
      customInfo.attr('data-item-url',itemInfo.URL);
      customInfo.attr('data-item-description',description);
      customInfo.attr('data-item-image',itemInfo.IMAGE);
      customInfo.attr('data-item-name',itemInfo.FINISH);
      customInfo.attr('data-item-quantity',totalQty);

      // - set up wheels
      $('.finish-quantity').each(function(){
        let tempQty = $(this).val();
        let tempSize = $(this).parent().find('.finish-size').val();
        updateCart(finish,tempSize,tempQty);
      });
      /*
      $('input[name="finish"]').each(function(){
        if($(this).prop('checked') && $(this).attr('data-finishitem') == 'custom') {
          console.log('we have a custom order;')
          $('.item-custom-information').addClass('snipcart-add-item');
        } else {
          $('.item-custom-information').removeClass('snipcart-add-item');
        }
      });*/
    }
  }

  //console.log(JSON.stringify(cartData.data, null, 4));
}


$('#wheel .cart-size select').change(function(){
  let bolt = $(this).children(':selected').attr('selected',true).data('bolt');
  let sku = $(this).children(':selected').attr('selected',true).data('sku');
  let price = $(this).children(':selected').attr('selected',true).data('price');
  let finish = $(this).children(':selected').attr('selected',true).data('finish');
  
  $('input[name="SKU"]').val(sku);
  $('input[name="bolt"]').val(bolt);
  $('input[name="price"]').val(price);
  $('input[name="Finish"]').val(finish);
  
  // reset options
  $("#wheel .cart-options.colors select").val($("#wheel .cart-options.colors select option:first").val());
  $("#wheel .cart-options.finishes select").val($("#wheel .cart-options.finishes select option:first").val());
  $("#wheel .cart-options.finishes select").prop('disabled',true);
});

$('#wheel .cart-options.colors select').change(function(){
  let currentPrice = $('#wheel .cart-size select').children(':selected').attr('selected',true).data('price');
  let colorPrice = $(this).children(':selected').attr('selected',true).data('price');
  let color = $(this).children(':selected').attr('selected',true).data('color');
  
  currentPrice = parseInt(currentPrice);
  colorPrice = parseInt(colorPrice);
  
  // Calculate new price
  let newPrice = currentPrice + colorPrice;
  
  // set new price and color
  $('input[name="price"]').val(newPrice);
  $('input[name="Color"]').val(color);
  
  // refresh finishes
  $("#wheel .cart-options.finishes select").val($("#wheel .cart-options.finishes select option:first").val());
  $("#wheel .cart-options.finishes select").prop('disabled',false);
});

$('#wheel .cart-options.finishes select').change(function(){
  let currentPrice = $('#wheel .cart-size select').children(':selected').attr('selected',true).data('price');
  let colorPrice = $('#wheel .cart-options.colors select').children(':selected').attr('selected',true).data('price');
  let finishPrice = $(this).children(':selected').attr('selected',true).data('price');
  let finish = $(this).children(':selected').attr('selected',true).data('finish');
  
  currentPrice = parseInt(currentPrice);
  colorPrice = parseInt(colorPrice);
  finishPrice = parseInt(finishPrice);
  
  // Calculate new price
  let newPrice = currentPrice + colorPrice + finishPrice;
  
  // set new price and finish
  $('input[name="price"]').val(newPrice);
  $('input[name="Finish"]').val(finish);
});


///// add more gallery /////
  $('.wheel-detail-gallery .navigation a').click(function(){
    var $this = $(this);
    var currentPage = $this.attr('data-page');
    var nextPage = parseInt(currentPage) + 1;
    var currentDisplay = $this.parent().prev();
    var getLast = $this.parent().prev().find('.column:last-child');
    var currentListCount = currentDisplay.find('.column').length;
    var nextListCount = currentListCount + 12;
    var maxListCount = $this.attr('data-max');
    var wheelID = $this.attr('data-wheel');
    
    // remove load more link
    if(nextListCount >= maxListCount) {
      $this.fadeOut();
    }
    
    // update page
    loadMoreGallery($this,nextPage)
    
    // display feed
    $.get('/ajax/getGallery.cfm?id=' + $this.data('id') + '&wheel=' + wheelID +'&page=' + currentPage, function getNewImages(data) {
      $(currentDisplay).append(data);
    });
  });
  
  function loadMoreGallery(element,page) {
    element.attr('data-page',page);

  }


//// mobile display
function getWidth() {
  return Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  );
}

if( $('#wheel .product-next-prev').length ) {
  if (getWidth() < 640 ) {
    $('.product-next-prev').insertBefore(  $('.wheel-detail-stage') );
  }
}

// snipcart build items
// build items array
function buildItems() {
  let items = [];
  $('.snipcart-add-item').each(function(){
    let item = {};
    item.id = $(this).data('itemId');
    item.price = $(this).data('itemPrice');
    item.url = $(this).data('itemUrl');
    item.description = $(this).data('itemDescription');
    item.image = $(this).data('itemImage');
    item.name = $(this).data('itemName');
    item.quantity = $(this).data('itemQuantity');
    //item.quantity = $(this).prev().find('input[name="quantity"]').val();
    items.push(item);
  });
  return items;
}

// snipcart
document.addEventListener('snipcart.ready', () => {
  // You can safely use window.Snipcart here

  $('.add-to-cart').click(function(){
    let products = buildItems();
    checkCart(products);    
  });

  function checkCart(products) {
    let cartList = Snipcart.store.getState().cart.items.items;
    let idList = cartList.map(e => ({id:e.id,uniqueId:e.uniqueId,quantity:e.quantity}));
    let updateList = [];
    let newList = [];

    for (let i = 0; i < products.length; i++ ){
      let itemChecked = cartList.find(e => e.id === products[i].id);
      if(itemChecked) {
        updateList.push(products[i]);
      } else {
        newList.push(products[i]);
      }
    }
    
    // update cart
    if(updateList.length) {
      updateCart(updateList,idList);
    }
    
    // add to cart
    addToCart(newList);

    openTheCart();
  }
  
  function addToCart(items) {
    for(var i = 0; i < items.length; i++) {
      Snipcart.api.cart.items.add(items[i]);
    }
  }

  function updateCart(items,cart) {
    for(var i = 0; i < items.length; i++) {
      let cartMatch = cart.find(e => e.id === items[i].id);
      let qty = parseInt(cartMatch.quantity) + parseInt(items[i].quantity);
      let update = {
        uniqueId: cartMatch.uniqueId,
        quantity: qty};
      Snipcart.api.cart.items.update(update);
    }
  }

  function openTheCart(){
    Snipcart.api.theme.cart.open();
  }

});